<template>
  <b-modal
    id="provider-modal"
    hide-header
    hide-footer
    centered size="lg"
    @hidden="closeModal"
  >
    <div class="header">
      <div class="modal-title">Cadastro de fornecedor</div>
      <span class="icon-box"><Close class="close-svg stroke" @click="closeModal"  /></span>
    </div>
    <div class="body">
      <validation-observer ref="productForm">
        <div class="row md-content">
          <div class="col-8">
            <b-form-group
              :invalid-feedback="'Campo obrigatório'"
              :state="isValidState('name')"
            >
              <label for="name">Nome / Razão Social</label>
              <b-form-input
                id="name"
                trim
                autocomplete="off"
                placeholder="Nome ou razão social do fornecedor"
                v-model="form.name"
              />
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group id="field_cnpj">
              <label for="field_cnpj">
                CNPJ
                <span class="help">(opcional)</span>
              </label>
              <b-form-input
                id="field_cnpj"
                trim
                autocomplete="off"
                v-mask="'##.###.###/####-##'"
                placeholder="00.000.000/0000-00"
                v-model="form.cnpj"
              />
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group id="field_email"
              :invalid-feedback="`${form.email ? 'E-mail inválido' : 'Campo obrigatório'}`"
              :state="isValidState('email')">
              <label label-for="email">
                E-mail
              </label>
              <b-form-input
                id="email"
                trim
                autocomplete="off"
                placeholder="E-mail do fornecedor"
                v-model="form.email"
              />
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group id="field_phone">
              <label label-for="phone">
                Telefone
                <span class="help">(opcional)</span>
              </label>
              <b-form-input
                id="phone"
                trim
                autocomplete="off"
                v-mask="'(##) #####-####'"
                placeholder="(00) 00000-0000"
                v-model="form.phone"
              />
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group
              id="field_phone2"
            >
              <label for="phone2">
                Telefone 02
                <span class="help">(opcional)</span>
              </label>
              <b-form-input
                id="phone2"
                trim
                autocomplete="off"
                v-mask="'(##) #####-####'"
                placeholder="(00) 00000-0000"
                v-model="form.phone2"
              />
            </b-form-group>
          </div>

          <div class="col-12">
            <hr/>
          </div>

          <!-- Address -->
          <div class="col-3">
            <b-form-group
              id="field_zipCode"
            >
              <label for="zipCode">
                CEP
                <span class="help">(opcional)</span>
              </label>
              <b-form-input
                id="zipCode"
                trim
                autocomplete="off"
                v-mask="'#####-###'"
                placeholder="00000-000"
                v-model="form.address.zipCode"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              id="field_address"
            >
              <label for="address">
                Logradouro
                <span class="help">(opcional)</span>
              </label>
              <b-form-input
                id="address"
                trim
                autocomplete="off"
                placeholder="Logradouro do fornecedor"
                v-model="form.address.address"
              />
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group
              id="field_number"
            >
              <label for="number">
                Número
                <span class="help">(opcional)</span>
              </label>
              <b-form-input
                id="number"
                ref="addressNumber"
                trim
                placeholder="01"
                autocomplete="off"
                v-model="form.address.number"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              id="field_neighborhood"
            >
              <label for="neighborhood">
                Bairro
                <span class="help">(opcional)</span>
              </label>
              <b-form-input
                id="neighborhood"
                trim
                autocomplete="off"
                placeholder="Bairro do fornecedor"
                v-model="form.address.neighborhood"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              id="field_complement"
            >
              <label for="complement">
                Complemento
                <span class="help">(opcional)</span>
              </label>
              <b-form-input
                id="complement"
                trim
                autocomplete="off"
                placeholder="Complemento do fornecedor"
                v-model="form.address.complement"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              id="field_state"
              :invalid-feedback="'Campo obrigatório'"
              :state="isValidState('state')"
            >
              <label for="state">
                Estado
              </label>
              <Autocomplete
                trim
                autocomplete="off"
                placeholder="Selecionar"
                v-model="form.address.state"
                :options="states.map(s => ({ label: s.initial, value: s.initial }))"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              id="field_city"
              :invalid-feedback="'Campo obrigatório'"
              :state="isValidState('city')"
            >
              <label for="city">
                Cidade
              </label>
              <Autocomplete
                trim
                autocomplete="off"
                :disabled="!form.address.state"
                placeholder="Selecionar"
                v-model="form.address.city"
                :options="form.address && form.address.state ? cities[form.address.state].cities.map(c => ({ label: c, value: c })) : []"
              />
            </b-form-group>
          </div>

          <!-- End Address -->
          <div class="col-12">
            <hr/>
          </div>

          <div class="col-12">
            <b-form-group
              id="field_observations"
            >
              <label for="observations">
                Observações
                <span class="help">(opcional)</span>
              </label>
              <b-form-input
                id="observations"
                trim
                autocomplete="off"
                placeholder="Adicionar observações"
                v-model="form.observations"
              />
            </b-form-group>
          </div>
        </div>
      </validation-observer>

      <div class="md-button">
        <b-button
          class="wh-button"
          variant="primary"
          size="lg"
          @click="form.id ? updateProvider() : createProvider()"
        >
            {{ form.id ? 'Atualizar ' : 'Salvar ' }} fornecedor
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import states from '@/utils/states'
import cities from '@/utils/cities'
import * as cep from 'cep-promise'
import { getCurrentClinic } from '../../utils/localStorageManager'

export default {
  name: 'ProductModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    Autocomplete: () => import('@/components/Autocomplete')
  },
  props: {
    provider: Object,
  },
  data() {
    const clinic = getCurrentClinic()
    return {
      states,
      cities,
      cityOptions: [],
      measureUnits: [],
      validated: false,
      form: {
        clinic_id: clinic.id,
        email: null,
        address: {
          state: null,
          city: null,
          country: 'Brasil',
        },
      },
      errors: {},
    }
  },
  methods: {
    handleState(value) {
      this.form.address.state = value;
      this.form.address.city = null;
      this.cityOptions = value ? this.cities[value].cities.map(c => ({ label: c, value: c })) : [];
    },
    handleCity(value) {
      this.form.address.city = value;
    },
    handleMeasureUnit(value) {
      this.form.measure_unit_id = value;
    },
    handleBatchControl(value) {
      this.form.batch_control = value;
    },
    createProvider() {
      if (!this.isValidForm()) return;
      const isLoading = this.$loading.show()
      if(this.form.email) this.form.email = this.form.email.toLowerCase();
      this.api.createProvider(this.form)
      .then(() => {
        this.clearForm();
        this.$toast.success('Fornecedor cadastrado com sucesso!')
        this.$bvModal.hide('provider-modal');
        this.$emit('onHide');
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    updateProvider() {
      if (!this.isValidForm()) return;
      const isLoading = this.$loading.show()
      if(this.form.email) this.form.email = this.form.email.toLowerCase();
      this.api.updateProvider(this.form.id, this.form)
      .then(() => {
        this.clearForm();
        this.$toast.success('Fornecedor atualizado com sucesso!')
        this.$bvModal.hide('provider-modal');
        this.$emit('onHide');
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    clearForm() {
      this.form = {
        clinic_id: this.form.clinic_id,
        address: { country: 'Brasil' }
      }
      this.errors = {};
    },
    isValidForm() {
      let isValid = true;
      const errors = {};
      if (!this.form.name || this.form.name.length === 0) {
        isValid = false;
        errors.name = true;
      };
      if (!this.form.email || !this.form.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        isValid = false;
        errors.email = true;
      };
      if (!this.form.address.state || this.form.address.state.length === 0) {
        isValid = false;
        errors.state = true;
      };
      if (!this.form.address.city || this.form.address.city.length === 0) {
        isValid = false;
        errors.city = true;
      };
      if (!this.form.address.country || this.form.address.country.length === 0) {
        isValid = false;
        errors.country = true;
      };
      this.errors = errors;
      return isValid;
    },
    isValidState(model) {
      return this.errors[model] !== true;
    },
    closeModal() {
      this.clearForm();
      this.$bvModal.hide('provider-modal');
    },
    searchCEP() {
      if (this.form.address.zipCode && this.form.address.zipCode.length === 9) {
        cep(this.form.address.zipCode)
          .then(res => {
            this.form.address.address = res.street
            this.form.address.neighborhood = res.neighborhood
            this.form.address.city = res.city
            this.form.address.state = res.state

            this.$refs.addressNumber.focus()
          })
          .catch(err => {
            this.$toast.error(err.message)
          })
      }
    },
  },
  watch: {
    provider: function(newValue) {
      if (newValue) {
        this.form = { ...newValue };
      } else {
        this.clearForm();
      }
    },
    'form.address.zipCode' : {
      handler (value) {
        if(value) this.searchCEP()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
<style lang="scss">
#provider-modal {
  .modal-content {
    .modal-body {
      padding: 0 !important;

      .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);

        .close-svg {
            height: 24px;
            width: 24px;
            fill: var(--neutral-500);
        }
      }
    }
  }
  .modal-title {
    font-size: 18px;
    color: var(--dark-blue);
    font-weight: 600;
  }
  .icon {
    width: 25px;
  }
  .md-content {
    padding: 30px 20px 10px 20px;
  }
  .md-button {
    text-align: right;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}
</style>
